import { graphql, useStaticQuery } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import GalleryImage from "./gallery-image";

type QueryType = {
  [key in ImageContentType]: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

type ImageContentType =
  | "empfang1"
  | "wartezimmer"
  | "soljan1"
  | "soljan2"
  | "soljan3"
  | "schroeter1"
  | "schoettes1";

const descriptions: { [key in ImageContentType]: string } = {
  empfang1: "Empfang",
  wartezimmer: "Wartezimmer",
  soljan1: "Räume Dr. Šoljan",
  soljan2: "Räume Dr. Šoljan",
  soljan3: "Räume Dr. Šoljan",
  schroeter1: "Räume Dr. Salamon",
  schoettes1: "Räume Dr. Schöttes",
};

const Gallery = () => {
  const data: QueryType = useStaticQuery(query);

  return (
    <div className="grid w-full grid-cols-12 gap-6">
      {Object.entries(data).map(([key, entry]) => (
        <div key={key} className="col-span-4 bg-beige rounded-xl">
          <GalleryImage
            image={entry.childImageSharp.gatsbyImageData}
            caption={descriptions[key as ImageContentType]}
          />
        </div>
      ))}
    </div>
  );
};

const query = graphql`
  query GalleryQuery {
    empfang1: file(relativePath: { eq: "Empfang_01.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    wartezimmer: file(relativePath: { eq: "Wartezimmer.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    soljan1: file(relativePath: { eq: "Raum-Soljan_01.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    soljan2: file(relativePath: { eq: "Raum-Soljan_02.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    soljan3: file(relativePath: { eq: "Raum-Soljan_03.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    schroeter1: file(relativePath: { eq: "Raum-Schroeter_01.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    schoettes1: file(relativePath: { eq: "Raum-Schoettes_02.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
  }
`;

export default Gallery;
