import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

export interface GalleryImageProps {
  image: IGatsbyImageData;
  caption: string;
  className?: string;
}

const GalleryImage: React.FC<GalleryImageProps> = ({
  image,
  caption,
  className,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleThumbnailClick = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <div className={classNames("h-full", className)}>
      <button
        onClick={handleThumbnailClick}
        className="relative h-full opacity-100 group"
      >
        <GatsbyImage
          image={image}
          alt={`${caption} Thumbnail`}
          className="rounded-xl"
        />
        <div className="absolute bottom-0 invisible w-full p-4 group-hover:visible bg-white/80">
          <span className="block">{caption}</span>
        </div>
      </button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-slate-900/30" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto w-12/12 md:w-10/12 rounded-xl md:h-5/6 aspect-square md:aspect-auto">
            <GatsbyImage
              className="w-full h-full rounded-xl"
              image={image}
              alt={caption}
            />
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default GalleryImage;
