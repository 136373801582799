import { HeadFC } from "gatsby";
import React from "react";
import Breadcrumbs from "../components/breadcrumbs";
import SEO from "../components/seo";

export interface SimplePageProps {
  children: React.ReactNode;
}

const SimplePage: React.FC<SimplePageProps> = ({ children }) => {
  return (
    <div className="flex flex-col gap-8 py-6 mx-auto container-7xl">
      <Breadcrumbs />
      <div>{children}</div>
    </div>
  );
};

export const Head: HeadFC = () => <SEO />;

export default SimplePage;
