/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import React from "react";
import SimplePageTemplate from "../templates/simple-page";
import Gallery from "../components/gallery";
const MDXLayout = SimplePageTemplate;
function _createMdxContent(props) {
  return React.createElement(React.Fragment, null, React.createElement("h1", {
    className: "text-4xl"
  }, "Räume"), "\n", React.createElement(Gallery));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
